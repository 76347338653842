import './index.css';
import { StoryAnimator } from 'photos-story-animator';
import parseQueryString from '../utils/parse-query-string.js';
import testmanifest from './test-manifest.json';

const STORY_PLAYER_ID = 'storyPlayer';

function resizeAnimator(storyAnimator) {
  return function() {
    setTimeout(() => {
      storyAnimator.width(window.innerWidth);
      storyAnimator.height(window.innerHeight);
    }, 50);
    // An issue where animator does not resize on window resize
    // The problem is that this function gets triggered too quickly before the window has finished measuring its new dimensions
    // Adding a small delay to wait for window to re-measure before setting story animator dimensions
  }
}

document.addEventListener('DOMContentLoaded', () => {
    const queryParams = parseQueryString(window.location.search);
    const opts = { reactBridge: true };
    if ('reactBridge' in queryParams) {
        opts.reactBridge = (queryParams.reactBridge === 'true');
    }

    // With latest react-native-webview, communication between react native webview
    // has changed. Introducing this flag to safely switch to new communication
    // Switched to use react-native-webview to upgrade ios to use WkWebview
    if ('reactBridgeV2' in queryParams) {
        opts.reactBridgeV2 = (queryParams.reactBridgeV2 === 'true');
    }

    if ('stylingVersion' in queryParams) {
        opts.stylingVersion = queryParams.stylingVersion;
    }

    const container = document.getElementById(STORY_PLAYER_ID);
    const storyAnimator = new StoryAnimator(container, opts);

    window.onresize = resizeAnimator(storyAnimator);

    if ('test' in queryParams) {
      //Used only for sanity and testing from player point of view
      //Launch the player passing in the test parameter to do some testing on story player itself https://localhost:3000/online/index.html?test=true

      storyAnimator.start(testmanifest);
      storyAnimator.on('playerLoadComplete', () => {
        console.log("----- press space bar to play/pause the video -----")
        document.addEventListener('keyup', (e) => {
          if(e.key === ' ') {
            // toggle play and pause
            if (storyAnimator.isPlaying) {
              storyAnimator.pause();
            } else {
              storyAnimator.play();
            }
          }
        });
      });
    }
});
